import type { Organism } from '@verndale/core';
import React from 'react';
import { createRoot } from 'react-dom/client';
/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reactModule = (Component: any, nodeList: NodeListOf<HTMLElement>) => {
  return nodeList.forEach(node =>
    createRoot(node).render(<Component {...node.dataset} />)
  );
};
/* eslint-enable @typescript-eslint/no-explicit-any */

/*

SAMPLE MODULE ENTRY USING REACT

{
  name: 'accordion',
  loader: () => import('./modules/accordion.js'),
  styles: () => import('../scss/modules/accordion.scss'),
  render: reactModule
}

*/

const modulesReact: Organism[] = [
  {
    name: 'masthead-zip-form',
    loader: () =>
      import('./react-components/contractor-locator-listing/masthead-zip-form'),
    render: reactModule
  },
  {
    name: 'shingle-detail-masthead',
    loader: () => import('./react-components/shingle-detail-masthead'),
    render: reactModule
  },
  {
    name: 'contractor-request-a-quote-form',
    loader: () =>
      import('./coveo/modules/contractor-request-a-quote-form/index'),
    render: reactModule
  },
  {
    name: 'product-masthead',
    loader: () => import('./react-components/product-masthead'),
    render: reactModule
  },
  {
    name: 'image-showcase-product-detail-masthead',
    loader: () => import('./react-components/image-showcase-product-detail-masthead'),
    render: reactModule
  },
  {
    name: 'loader',
    loader: () => import('./react-components/listing-states/loader'),
    styles: () => import('../scss/components/_loader.scss'),
    render: reactModule
  },
  {
    name: 'press-release-listing',
    loader: () => import('./react-components/press-release-listing/index.js'),
    styles: () => import('../scss/modules/press-release-listing.scss'),
    render: reactModule
  },
  {
    name: 'error-message',
    loader: () => import('./react-components/listing-states/error'),
    styles: () => import('../scss/components/_error-message.scss'),
    render: reactModule
  },
  {
    name: 'city-directory-listing',
    loader: () => import('./react-components/city-directory-listing/index'),
    styles: () => import('../scss/modules/city-directory-listing.scss'),
    render: reactModule
  },
  {
    name: 'project-listing',
    loader: () => import('./coveo/modules/project-listing'),
    styles: () => import('../scss/modules/project-listing.scss'),
    render: reactModule
  },
  {
    name: 'contractor-listing',
    loader: () => import('./coveo/modules/contractor-listing'),
    styles: () => import('../scss/modules/contractor-listing.scss'),
    render: reactModule
  },
  {
    name: 'city-listing',
    loader: () => import('./coveo/modules/city-listing'),
    styles: () => import('../scss/modules/contractor-listing.scss'),
    render: reactModule
  },

  {
    name: 'search-results-listing',
    loader: () => import('./coveo/modules/search-results-listing'),
    styles: () => import('../scss/modules/search-results-listing.scss'),
    render: reactModule
  },

  {
    name: 'documents-listing',
    loader: () => import('./coveo/modules/documents-listing'),
    styles: () => import('../../src/scss/modules/documents-listing.scss'),
    render: reactModule
  },
  {
    name: 'territory-manager-lookup',
    loader: () => import('./react-components/territory-manager-lookup'),
    styles: () => import('../scss/modules/territory-manager-lookup.scss'),
    render: reactModule
  },
  {
    name: 'document-listing',
    loader: () => import('./react-components/document-listing'),
    styles: () => import('../scss/modules/document-listing.scss'),
    render: reactModule
  },

  {
    name: 'distributor-locator',
    loader: () => import('../scripts/react-components/distributor-locator'),
    styles: () => import('../scss/modules/distributor-locator.scss'),
    render: reactModule
  },
  {
    name: 'territory-manager-listing',
    loader: () => import('./react-components/territory-manager-listing'),
    styles: () => import('../scss/modules/territory-manager-listing.scss'),
    render: reactModule
  },
  {
    name: 'request-a-quote-modal',
    loader: () => import('./coveo/components/request-a-quote-modal/index'),
    styles: () => import('../scss/components/_request-a-quote-modal.scss'),
    render: reactModule
  },

  {
    name: 'predictive-search',
    loader: () => import('./coveo/components/predictive-search'),
    render: reactModule
  },
  {
    name: 'document-cart',
    loader: () => import('./react-components/document-cart'),
    styles: () => import('../scss/modules/document-cart.scss'),
    render: reactModule
  },
  {
    name: 'user-review-landing-thank-you',
    loader: () =>
      import('./react-components/user-review-landing/step-5-thank-you'),
    styles: () =>
      import('../../src/scss/modules/user-review-landing-thank-you.scss'),
    render: reactModule
  },

  {
    name: 'user-review-landing-redirect-to-google-review',
    loader: () =>
      import(
        './react-components/user-review-landing/step-4-redirect-to-google-review'
      ),
    styles: () =>
      import(
        '../../src/scss/modules/user-review-landing-redirect-to-google-review.scss'
      ),
    render: reactModule
  },

  {
    name: 'standalone-searchbox',
    loader: () => import('./coveo/modules/standalone-searchbox'),
    render: reactModule
  },

  {
    name: 'quiz-results',
    loader: () => import('./react-components/quiz-results'),
    styles: () => import('../scss/modules/quiz-results.scss'),
    render: reactModule
  },
  {
    name: 'user-review-landing',
    loader: () => import('./react-components/user-review-landing'),
    styles: () => import('../scss/modules/user-review-landing.scss'),
    render: reactModule
  },
  {
    name: 'quiz-form',
    loader: () => import('./react-components/quiz-form'),
    styles: () => import('../scss/modules/quiz-form.scss'),
    render: reactModule
  },
  {
    name: 'blog-listing',
    loader: () => import('./react-components/blog-listing'),
    styles: () => import('../scss/modules/blog-listing.scss'),
    render: reactModule
  },
  {
    name: 'product-listing',
    loader: () => import('./coveo/modules/product-listing'),
    styles: () => import('../scss/modules/product-listing.scss'),
    render: reactModule
  },
  {
    name: 'shingle-result-listing',
    loader: () => import('./coveo/modules/shingle-result-listing'),
    styles: () => import('../scss/modules/shingle-result-listing.scss'),
    render: reactModule
  },
  {
    name: 'shingle-comparison-app',
    loader: () => import('./react-components/shingle-comparison-app'),
    styles: () => import('../scss/modules/shingle-comparison-table.scss'),
    render: reactModule
  },
  {
    name: 'mockApi',
    loader: () => import('./react-components/mockApi'),
    render: reactModule
  },
  {
    name: 'commercial-document-package',
    loader: () => import('./react-components/commercial-document-package'),
    styles: () => import('../scss/modules/commercial-document-package.scss'),
    render: reactModule
  },
  {
    name: 'contractor-finder',
    loader: () => import('../scripts/coveo/modules/contractor-finder'),
    styles: () => import('../scss/modules/contractor-finder.scss'),
    render: reactModule
  }
];

export default [...modulesReact];
